<template>
  <page :data="pageData" :is-child="false">
    <template #cmsleftcomponents>
      <form @submit.prevent="submit">
        <div class="w-full justify-start flex mr-4 mb-2 underline text-red-300">
          <div class="cursor-pointer" @click="$router.push('/topupaccount')">Add +</div>
        </div>
        <b-field>
          <b-select
            v-model="selectedOption"
            placeholder="Select an option"
            required
          >
            <option
              v-for="option in options"
              :key="option.Oid"
              :value="option.Oid"
              >{{ option.Name }}</option
            >
          </b-select>
        </b-field>
        <b-field>
          <b-select
            v-model="selectedOptionPayment"
            placeholder="Select an option"
            required
          >
            <option
              v-for="option in paymentMethod"
              :key="option.Oid"
              :value="option.Oid"
              >{{ option.Name }}</option
            >
          </b-select>
        </b-field>
        <div class="grid grid-cols-3 text-center">
          <div v-for="(item, n) in amountOptions" :key="n" class="mb-2 cursor-pointer" @click="selectAmount = n, fieldTopup = item">
            <div class="p-2 border rounded-md m-2 hover:border-red-400" :class="selectAmount == n ? 'border-red-400' : 'border-gray-400'">
              <b-icon icon="cash" class="my-auto text-green-300"> </b-icon>
              <div class="font-bold">
                {{ formatNumber(item) }}
              </div>
            </div>
          </div>
        </div>
        <div @click="activeFieldTopup = true" class="text-center border border-gray-400 rounded-2xl p-1 mt-4 mb-4">
          <div v-if="!activeFieldTopup">+ Or, type the amount</div>
          <div v-else class="w-full justify-center flex" style="height: 40px">
            <b-field class="fieldhidden">
              <b-input v-model="fieldTopup" type="number" min="0" maxlength="20" class="absolute w-24"></b-input>
            </b-field>
            <!-- <div class="relative w-full justify-center flex">
              <div class="absolute text-center font-bold" style="bottom: 20px">{{ fieldTopup !== 0 || fieldTopup !== '' ? fieldTopup : '' }}</div>
            </div> -->
          </div>
        </div>

        <div>
          <b-button type="is-primary" class="w-full" native-type="submit"
            >Save</b-button
          >
        </div>
      </form>
    </template>
  </page>
</template>

<script>
import Page from '@/components/vaddress/cmspages/Page'
const pageData = {
  config: {
    Header: {
      Title: 'Top Up'
    }
  },
  cmsLeft: {
    Data: []
  }
}
export default {
  components: { Page },
  data () {
    const webcontent = this.$store.state.webcontent
    const topupAccount = webcontent && webcontent.response['/user/dashboard'].Topup.Account
    const paymentMethod = webcontent && webcontent.response['/user/dashboard'].Topup.PaymentMethod
    const AmountOptions = webcontent && webcontent.response['/user/dashboard'].Topup.AmountOptions

    return {
      amountOptions: AmountOptions,
      fieldTopup: 0,
      activeFieldTopup: false,

      selectAmount: -1,
      paymentMethod: paymentMethod.Source,
      selectedOptionPayment: paymentMethod && paymentMethod.Default.Oid,
      options: topupAccount && topupAccount.Source,
      selectedOption: topupAccount && topupAccount.Default.Oid
    }
  },
  methods: {
    async submit () {
      if (this.fieldTopup === 0 || this.fieldTopup === '') {
        return this.$buefy.snackbar.open({
          message: 'Please fill amount Top Up',
          type: 'is-warning',
          position: 'is-bottom',
          duration: 800
        })
      }
      const loading = this.$buefy.loading.open()
      try {
        const data = {
          Account: this.selectedOption,
          Amount: parseFloat(this.fieldTopup),
          PaymentMethod: this.selectedOptionPayment
        }
        const res = await this.$baseApi.post('/user/orders/topup', data)
        this.$router.replace('/user/checkout/va?Oid=' + res.Oid + '&topup=true')
        this.fieldTopup = 0
        this.activeFieldTopup = false
        this.selectAmount = -1
        this.$buefy.snackbar.open({
          message: 'Successfully Top Up',
          type: 'is-success',
          position: 'is-bottom',
          duration: 800
        })
      } catch (err) {
        loading.close()
        console.log(err)
        return this.$buefy.snackbar.open({
          message: err.message,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 800
        })
      } finally {
        loading.close()
      }
    }
  },
  computed: {
    pageData () {
      return pageData
    }
  }
}

</script>

<style>
.select select {
  width: 100vh;
}
.fieldhidden {
  ::-webkit-inner-spin-button,
  -webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  input{
    background-color: transparent;
    border: none;
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }
  }
}
</style>
